.report-footer {
    display: flex;
    justify-content: space-between;
    padding: 14px 25px;
    border-top: 2px solid #847979;
    font-size: 14px;
    height: auto;
    box-sizing: border-box;
}

.footer-left {
    display: flex;
    flex-direction: column;
}

.footer-right {
    position: relative;
    text-align: right;
    margin-left: auto;
}

.footer-right p {
    margin: 0;
}
