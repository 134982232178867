/* Center-align the report type */
.report-type {
  text-align: center; /* Center the text */
  font-size: 24px; /* Increase the font size for prominence */
  font-weight: 600; /* Make the text bold */
  color: #000000; /* Use a color to match the style, optional */
  margin-bottom: 20px; /* Add some spacing below */
}

/* Existing CSS below */
.patient-report {
  width: 800px;
  margin: auto;
  background-color: #fff;
  font-family: Arial, sans-serif;
  color: #000;
  padding-left: 20px;
}

.tech .column {
  flex: 1;
  padding: 0 20px;
  text-align: left;
  position: relative;
}

/* Add vertical line between each column */
.column:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #333; /* Black vertical line */
  height: 100%;
}

.patient-info p,
.report-info p,
.registration-info p {
  margin: 5px 0;
}

.report-header,
.report-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #847979;
}

.report-content {
  padding: 20px;
}

.view-details,
.impression,
.recommendation,
.notes {
  margin-bottom: 20px;
  margin-right: 20px;
}

.view-details h5 {
  font-size: 18px; /* Adjust the size for the heading */
  font-weight: bold; /* Make the heading bold */
  margin-bottom: 10px; /* Add space below the heading */
}

.view-details ul {
  padding-left: 40px; /* Indent the content by one tab space */
  list-style-type: disc; /* Use disc for the bullet points */
}

.view-details li {
  margin-bottom: 10px; /* Add space between list items */
  font-size: 16px; /* Adjust font size for list items */
}

.impression h5 {
  font-size: 18px; /* Adjust the size for the heading */
  font-weight: bold; /* Make the heading bold */
  margin-bottom: 10px; /* Add space below the heading */
}

.impression ul {
  padding-left: 40px; /* Indent the content by one tab space */
  list-style-type: disc; /* Use disc for the bullet points */
}

.impression li {
  margin-bottom: 10px; /* Add space between list items */
  font-size: 16px; /* Adjust font size for list items */
}

/* Recommendations Section */
.recommendation h5 {
  font-size: 18px; /* Adjust the size for the heading */
  font-weight: bold; /* Make the heading bold */
  margin-bottom: 10px; /* Add space below the heading */
}

.recommendation ul {
  padding-left: 40px; /* Indent the content by one tab space */
  list-style-type: disc; /* Use disc for the bullet points */
}

.recommendation li {
  margin-bottom: 10px; /* Add space between list items */
  font-size: 16px; /* Adjust font size for list items */
}

/* Notes Section */
.notes h5 {
  font-size: 18px; /* Adjust the size for the heading */
  font-weight: bold; /* Make the heading bold */
  margin-bottom: 10px; /* Add space below the heading */
}

.notes ul {
  padding-left: 40px; /* Indent the content by one tab space */
  list-style-type: disc; /* Use disc for the bullet points */
}

.notes li {
  margin-bottom: 10px; /* Add space between list items */
  font-size: 16px; /* Adjust font size for list items */
}

.dicom-images {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.dicom-images img {
  max-width: 300px;
  margin-right: 10px;
}

.xray-view {
  text-align: center;
  margin-right: 20px;
}

.xray-pic {
  max-width: 40%;
  height: auto;
  display: inline-block;
}

.footer-divider {
  border: 0;
  border-top: 2px solid #333;
  margin: 20px 0;
  width: 100%;
}

.section-divider {
  margin: 20px 0;
  border: 1px solid #000;
}

.section-heading {
  font-size: 18px; /* Same size for both headings */
  font-weight: bold; /* Make it bold */
  margin-bottom: 10px; /* Add some space below */
  color: #000; /* Ensure consistent color */
}

.patient-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.patient-details p strong {
  font-weight: bold;
}

.column {
  flex: 1;
  padding: 0 20px;
}

.column p {
  margin: 0;
}

.signature-img {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
}
.print-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.cancel-button {
  background-color: #ff4d4d; /* Red background */
  color: #fff; /* White text */
  border: none;
  padding: 6px 12px; /* Adjust padding */
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 10px; /* Add space between cancel and print button */
  display: flex;
  align-items: center;
}

.cancel-button:hover {
  background-color: #cc0000; /* Darker red on hover */
}

.print-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px; /* Adjust padding to make it smaller */
  font-size: 14px; /* Reduce font size */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center; /* Ensure icon and text are vertically aligned */
}

.print-button:hover {
  background-color: #0056b3;
}

.print-icon,
.cancel-icon {
  margin-right: 8px; /* Add space between the icon and text */
  font-size: 18px; /* Adjust icon size */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust this height as needed */
}

.patient-report {
  width: 800px;
  margin: auto;
  background-color: #fff;
  font-family: Arial, sans-serif;
  color: #000;
  padding-left: 20px;
}
