.report-header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the top to avoid overlap */
    border-bottom: 2px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allow content to wrap if the space is limited */
}

.left-section {
    display: flex;
    align-items: flex-start; /* Align the logo and text to the top */
    flex: 1; /* Allow the left section to take up available space */
}

.clinic-logo {
    max-width: 120px;
    height: auto;
    margin-right: 20px;
}

.clinic-details {
    text-align: left;
}

.hospital-name {
    margin: 0;
    font-size: 28px;
    font-weight: bold;
    color: #014c9c;
    text-transform: uppercase;
    letter-spacing: 0.8px;
}


.clinic-details p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
    font-weight: 600;
}

.right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0; /* Prevent the right section from shrinking */
}

.contact-info p {
    margin: 0 0 5px 0;
    white-space: nowrap; /* Prevent contact info from breaking in the middle */
}

.icon {
    margin-right: 8px;
    vertical-align: middle;
}
