.qr-scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0;
}

.qr-scanner-window {
  width: 300px; /* Adjust the size of the scanner window as needed */
  height: 300px;
  border: thick groove #5C9CFE;
  overflow: hidden;
  margin: 0;
  border-radius: 15px;
}
