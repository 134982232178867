@media only screen and (max-width: 1024px) {
    html {
        font-size: 60%;
    }
}

;
@media only screen and (max-width: 1280px) {
    html {
        font-size: 90%;
    }
}

;
@media only screen and (max-width: 1536px) {
    html {
        font-size: 114%;
    }
}

;
@media only screen and (max-width: 1920px) {
    html {
        font-size: 157.5%;
    }
}

;